.create-user-page {
  .title {
    font-weight: 650;
  }

  form {
    margin-top: 10px;
    padding: 16px;
    -webkit-box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
    box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    .error-box, .message-box {
      color: white;
      font-weight: 600;
    }

    button {
      color: white;
      transition: transform 0.2s;
      font-weight: 600;
      
      &:not(:first-child) {
        margin-left: 8px;
      }

      &:hover {
        color: white;
        transform: scale(1.05);
      }
    }
  }
}