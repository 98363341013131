.seg-container {
  padding: 20px;
  margin: 20px 0;
  max-width: 50%;

  margin-left: auto;
  margin-right: auto;
}

.segmented {
    display:flex;
    flex-flow:row wrap;
    box-sizing:border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
    font-size:90%;
    font-weight: 500;
    text-align:center;
}
.segmented label {
    display:block;
    flex:1;
    box-sizing:border-box;
    border:1px solid #167efb;
    border-right:none;
    color:#167efb;
    margin:0;
    padding:.4em;
    cursor: pointer;
    user-select:none;
    -webkit-user-select:none;
}
.segmented label.checked {
    background:#167efb;
    color:#fff;
}
.segmented.inverted label {
    border-color:#fff;
    color:#fff;
    background:none;
}
.segmented.inverted label.checked {
    background:#fff;
    color:inherit;
}
.segmented label:first-child {
    border-radius:.4em 0 0 .4em;
    border-right:0;
}
.segmented label:last-child {
    border-radius:0 .4em .4em 0;
    border-right:1px solid;
}
.segmented input[type="radio"] {
    appearance:none;
    -webkit-appearance:none;
    visibility:hidden;
    margin:0;
    position: absolute;
}
