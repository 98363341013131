.buttonGroup button {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-right: 10px;

  background-color: #0093FF;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}

.buttonGroup button.warning {
  background-color: #D89109;
}

.buttonGroup button.warning-severe {
  background-color: #D80909;
}

.buttonGroup button.success {
  background-color: #26BF13;
}

.container-floating {
    -webkit-box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
    box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 16px;
}

#directReportsHeader {
  -webkit-box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 14px;
}

#directReportsHeader h2 {
  font-weight: 700;
  font-size: 28px;
}

#directReportsHeader input {
  width: 100%;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  font-size: 18px;
}

#directReportsHeader input:focus {
  outline: none;
}

.container-floating .danger {
  color: #D80909;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 0px;
}
