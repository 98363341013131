.task {
    border-radius: 8px;
    padding: 8px;
    background-color: white;
    position: relative;
    margin-bottom: 10px;
}

.separator {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    height: 3px;
}

.newTaskButton {
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    padding: 8px;
    padding-left: 20px;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%!important;
}

.newTaskButton div a {
    border: none;
    color: #167efb;
    padding-left: 7%;
}

.newTaskButton input {
    width: 100%;
    border: none;
    color: rgba(0, 0, 0, 0.5);
}

.priorityLabel {
    width: 35px;
    height: 35px;
    border-radius: 17.5px;

    /* centering */
    position: absolute;
    top: 50%;
    margin-top: -17.5px;
}

.aboveLine {
    background-color: #55A6FF;
}

.belowLine {
    background-color: #2DBF46;
}

.priorityLabel p {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    margin: 0;
    position: absolute;
    top: 20%;
    left: 23%;
}

.task .media-body {
    padding-top: 10px;
    padding-bottom: 10px;
    display: table-cell;
    vertical-align: middle;
    margin-left: 50px;
    overflow: hidden;
}

.task .media-body h5, h6 {
    margin-bottom: 0;
}
