.iconButton {
  padding: 4px 14px;
  background-color: #3EB4B7;
  font-weight: 600;
  border: none;
  color: white;
  border-radius: 8px;

  img, span {
    display: inline;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-bottom: 3px;
    object-fit: contain;
  }

  &:focus {
    outline: none;
  }

  img {
    filter: brightness(0) invert(1);
  }
}