*, body {
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, sans-serif!important;
    margin-top: 0px;
}

@media (min-width: 450px) {
    .container-small {
        width: 100%;
    }
    .container-large {
        width: 970px;
    }
}
@media (min-width: 992px) {
    .container-small {
        width: 500px;
    }
    .container-large {
        width: 1170px;
    }
}
@media (min-width: 1200px) {
    .container-small {
        width: 700px;
    }
    .container-large {
        width: 1500px;
    }
}

.container-small, .container-large {
    max-width: 100%;
}

.error {
    color: red;
    font-weight: bold;
}

.card-shadow {
    -webkit-box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
            box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
}

.a-no-highlight {
    color: rgba(0, 0, 0, 0.5)!important;
    text-decoration: none!important;
}

.a-no-highlight:hover {
    color: rgba(0, 0, 0, 0.5)!important;
}
