#userEditContext .field h4 {
  margin-bottom: 0px;
  font-weight: 650;
}

#userEditContext .field input {
  font-size: 20px;
  border: none;
  border-bottom: rgba(0, 0, 0, 0.1) 2px solid;
  transition: ease-in-out, border-bottom .35s ease-in-out;
}

#userEditContext .field {
  margin-bottom: 25px;
}
