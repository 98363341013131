.userReport {
  -webkit-box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 14px;
  margin-left: 24px;

  img {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-bottom: 3px;
  }

  p {
    display: inline;
    font-weight: 550;
  }
}