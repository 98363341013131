.departmentRow {
  -webkit-box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 14px;

  .title {
    font-size: 22px;

    img {
      height: 20px;
      width: 20px;
      float: right;
      margin-top: 3px;
      text-align: right;
      clear: both;
      vertical-align: middle;
      transform: rotate(180deg);
      filter: brightness(0);
    }
  }
}

.departmentRowLink {
  color: black;
  text-decoration: none;

  &:hover {
    color: black;
  }
}