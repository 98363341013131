.button {
    text-align: center!important;
    height: 35px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #007AFF;
    font-weight: 600;
}

.logo {
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.box {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    display: block;

    -webkit-box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
    box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
    width: 400px;
    border-radius: 10px;
    padding: 16px;
}

.box .form-section h4 {
    font-weight: 620;
    font-size: 22px;
}

.box .form-section input {
    width: 100%;
    border: none;
    border-bottom: rgba(0, 0, 0, 0.1) 2px solid;
    transition: ease-in-out, border-bottom .35s ease-in-out;
}

.box .form-section input:focus {
    outline-width: 0;
    outline: none;
    border: none;
    border-bottom: #007AFF 2px solid;
}

.box .form-section select {
    width: 100%;
}

.form-section {
    margin-bottom: 25px;
}

.separator {
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
}

@media (max-width: 450px) {
    .box {
        width: 90%;
        box-shadow: none;
        -webkit-box-shadow: none;
        padding: 0px;
    }

    .box .foot-button {
        margin-bottom: 50px!important;
    }
}

.error-box {
    min-height: 40px;
    background-color: #D0021B;
    margin-bottom: 24px;
    border-radius: 6px;
    padding: 8px;
}

.error-box p img {
    height: 20px;
}

.error-box p {
    color: white;
    font-weight: 500;
    text-align: left;
    font-size: 15px;
    margin-bottom: 2px;
}

.message-box {
    min-height: 40px;
    background-color: #007AFF;
    margin-bottom: 24px;
    border-radius: 6px;
    padding: 8px;
}

.message-box p img {
    height: 20px;
}

.message-box p {
    color: white;
    font-weight: 500;
    text-align: left;
    font-size: 15px;
    margin-bottom: 2px;
}
