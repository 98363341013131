#teamMembersHeader {
  -webkit-box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 14px;
}

#teamMembersHeader h2 {
  font-weight: 700;
  font-size: 28px;
}

#teamMembersHeader input {
  width: 100%;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  font-size: 18px;
}

#teamMembersHeader input:focus {
  outline: none;
}
