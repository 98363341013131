.modal-title {
    font-weight: 750;
    font-size: 36px;
}

.modal-body form input {
    border-radius: 10px;
    border: 3px solid rgba(0, 0, 0, 0.2);

    padding: 20px;
    padding-left: 10px;

    color: black;
}

.modal-body {
    padding-bottom: 0px;
}

.modal-body form label {
    font-weight: 500;
}

.modal-content .button-holder {
    display: grid;

    margin-left: auto;
    margin-right: auto;
    width: 100%;

    padding: 15px;
    padding-top: 5px;
}

.modal-content .button-holder > button {
    margin-left: 5px;
    margin-right: 5px;

    border-radius: 12px;
    height: 42px;

    color: white;
}
