#userInfoContext .infoField h4 {
  margin-bottom: 0px;
  font-weight: 650;
}

#userInfoContext .infoField p {
  font-size: 18px;
}

#userInfoContext .infoField {
  margin-bottom: 25px;
}
