@media only screen and (max-width: 1000px) {
  .teamPage {
    .userSection {
      .task {
        h5 {
          font-size: 14px;
        }

        img {
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .teamPage {
    .userSection {
      .row {
        display: block;
  
        .col-4 {
          flex: 0 0 100%;
          max-width: 100%;
          margin-left: 25px;
        }
      }
    }
  }
}