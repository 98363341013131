.userRow {
  -webkit-box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 14px;
}

.userRow h3 {
  font-size: 22px;
  color: black;
}

.userRow h4 {
  font-size: 18px;
  color: #969696;
}

.userRow *:hover {
  text-decoration: none;
}
