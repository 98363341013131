.pageHeader {
  -webkit-box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 0 10px -1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 14px;

  h2 {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 10px!important;
    display: inline;
  }

  input {
    width: 100%;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 12px;
    padding-top: 10px;
    font-size: 18px;

    &:focus {
      outline: none;
    }
  }

  .actions {
    display: inline;
    margin-left: 10px;

    .iconButton:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.pageSection {
  margin-bottom: 35px;
}

.page {
  .title {
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .subtitle {
    font-weight: 600;
    font-size: 24px;
  }

  .pageSection {
    margin-top: 20px;
  }

  ul {
    margin-top: 20px;
    margin-bottom: 5px;
    padding-left: 0px;

    li {
      display: inline;
      font-weight: 600;

      @media screen and (max-width: 600px) {
        display: block;
        margin-left: 0px!important;
      }
      
      a {
        svg, img {
          height: 20px;
          width: 20px;
          object-fit: contain;
        }
      }

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}