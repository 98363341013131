.searchHeader {
    width: 100%;
    border-radius: 10px;

    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;

    -webkit-box-shadow: -1px 0 10px -2px rgba(0, 0, 0, 0.3);
            box-shadow: -1px 0 10px -2px rgba(0, 0, 0, 0.3);
}

.searchHeader a {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.5);
}

.searchHeader input {
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
